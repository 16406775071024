@import "minima";

.contact-list {
  list-style: none;
  margin-left: 0;
  display: flex;
}

.contact-list > li {
  margin-left: 0.5rem;
}

.contact-list > li:before {
  content: '\2022';
  margin-right: 0.5rem;
}

.contact-list > li:first-of-type {
  margin-left: 0;
}

.contact-list > li:first-of-type:before {
  content: '';
  margin-right: 0;
}

.contact-list > li > a > img {
  vertical-align: sub;
}

.link {
  width: 16px;
  height: 16px;
  margin-right: 0.25rem;
}

.resume {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  background-color: rgb(82, 86, 89);
}

.flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.no-padding {
  padding: 0;
}